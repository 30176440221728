<template>
    <div class="correct">
        <div class="title">绑定失败，请稍后重试</div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                token: ''
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>